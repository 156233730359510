import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import { getImage } from "gatsby-plugin-image"
import SectionHeading from "../../SectionHeading/SectionHeading"
import Subsection from "./Subsection"
import TranslateClient from "../../../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require("../../../helpers")
import { LanguageContext } from "../../../context/Language"
import { Image } from "../../Image"

interface IRightOrLeftPrimaryImage {
  section?: any
  imageAlt: string
  reverse?: boolean
}

const RightOrLeftPrimaryImage: React.FC<IRightOrLeftPrimaryImage> = ({
  section,
  reverse,
  imageAlt,
}) => {
  const imageSrc1 = getImage(section?.primaryImage?.localFile)

  const headline = section?.header
  const [text, setText] = useState({ headline })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ headline })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([headline])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <div css={[tw`sm:pt-16 pt-6 w-full`, reverse && tw`sm:pt-16 pt-6`]}>
      <SectionHeading textStyles={tw`sm:mb-16 mb-10 ml-0 mr-0 font-extrabold`}>
        {text?.headline}
      </SectionHeading>
      <div
        css={[
          tw`flex lg:flex-row flex-col-reverse justify-center`,
          reverse && tw`flex lg:flex-row-reverse flex-col`,
        ]}
      >
        <div css={[tw`lg:w-1/2 w-full`]}>
          {section?.subSection?.map(({ title, description }, i) => (
            <Subsection title={title} description={description} key={i} />
          ))}
        </div>
        <Image
          src={imageSrc1}
          publicURL={section?.primaryImage?.localFile?.publicURL}
          alt={imageAlt}
          css={[
            tw`lg:w-1/2 w-full object-contain lg:ml-16 lg:mb-0 mb-12`,
            reverse && tw`lg:mr-16 lg:ml-0`,
          ]}
        />
      </div>
    </div>
  )
}

export default RightOrLeftPrimaryImage
