import { graphql, PageProps } from "gatsby"
import React from "react"
import { SEO } from "../../components/SEO"
import TextHeavyTemplate from "../../components/TextHeavyTemplate/TextHeavyTemplate"
import { TemplateProps, TextHeavyTemplatePageData } from "../templates"

const TextHeavy: React.FC<PageProps> = ({
  data,
  location,
}: TemplateProps<TextHeavyTemplatePageData>) => {
  const { title, content, featuredImage, template } = data.wpPage

  return (
    <div className="page-content" css={["h2 {margin-right: 1.25rem; margin-left: 1.25rem;}"]}>
      <SEO url={location.href} title={title} />
      <TextHeavyTemplate
        title={title || ""}
        content={content || ""}
        featureImage={featuredImage || null}
        sections={template?.textHeavyPage?.section || null}
      />
    </div>
  )
}

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      featuredImage {
        node {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      template {
        ... on WpTemplate_TextHeavy {
          templateName
          textHeavyPage {
            section {
              header
              layout
              primaryImage {
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                  }
                }
              }
              subSection {
                title
                description
                image {
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default TextHeavy
