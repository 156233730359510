import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import ImageHero from "../Hero/ImageHero"
import AlternatingTextAndImage from "./TextHeavySections/AlternatingTextAndImage"
import TopPrimaryImage from "./TextHeavySections/TopPrimaryImage"
import RightOrLeftPrimaryImage from "./TextHeavySections/RightOrLeftPrimaryImage"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"
import { Image } from "../Image"
interface ITextHeavyTemplate {
  title: string
  content: string
  featureImage: any
  sections: any
}

const TextHeavyTemplate: React.FC<ITextHeavyTemplate> = ({
  title,
  featureImage,
  content,
  sections,
}) => {
  const [text, setText] = useState({ title, content })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ title, content })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([title, content])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <section
      css={[tw`h-full flex flex-col justify-center items-center w-full`]}
    >
      <ImageHero
        image={featureImage}
        aria-hidden
        styling={tw`xl:h-80 h-72`}
      />
      <div
        css={[
          tw`h-full flex flex-col justify-center items-center relative w-full`,
        ]}
      >
        <Image
          src="/imageHeavyTemplate.jpg"
          alt=""
          aria-hidden
          css={[
            tw`absolute left-0 top-0 h-40 w-full -z-1 object-center object-cover`,
          ]}
        />
        <div
          css={[
            tw`pt-20 m-auto h-full sm:w-full max-w-screen-md xs:mb-0 mb-10 lg:pr-0 lg:pl-0 pr-7 pl-7`,
          ]}
        >
          <h2 css={[tw`text-center mb-4 text-3xl font-extrabold`]}>
            {text?.title}
          </h2>
          <p
            dangerouslySetInnerHTML={{ __html: text?.content }}
            css={[tw`text-center text-lg font-medium`]}
          />
        </div>
        <div
          css={tw`h-full w-full flex flex-col justify-center items-center max-w-screen-lg px-7 lg:px-0`}
        >
          {sections?.map(section => (
            <>
              {section?.layout === "Alternating Text and Image" && (
                <AlternatingTextAndImage section={section} />
              )}
              {section?.layout === "Top Primary Image" && (
                <TopPrimaryImage section={section} />
              )}
              {section?.layout === "Right Primary Image" && (
                <RightOrLeftPrimaryImage
                  section={section}
                  imageAlt="Heavy Template Section3 Img"
                />
              )}
              {section?.layout === "Left Primary Image" && (
                <RightOrLeftPrimaryImage
                  section={section}
                  imageAlt="Heavy Template Section4 Img"
                  reverse
                />
              )}
            </>
          ))}
        </div>
      </div>
    </section>
  )
}

export default TextHeavyTemplate
