import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import { getImage } from "gatsby-plugin-image"
import SectionHeading from "../../SectionHeading/SectionHeading"
import TranslateClient from "../../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../../helpers"
import { LanguageContext } from "../../../context/Language"
import { Image } from "../../Image"

interface IAlternatingTextAndImage {
  section: any
}

const AlternatingTextAndImage: React.FC<IAlternatingTextAndImage> = ({
  section,
}) => {
  const imageSrc1 = getImage(section?.subSection[0]?.image.localFile)
  const imageSrc2 = getImage(section?.subSection[1]?.image.localFile)

  const headline = section?.header
  const titleOne = section?.subSection[0]?.title
  const descriptionOne = section?.subSection[0]?.description
  const titleTwo = section.subSection[1]?.title
  const descriptionTwo = section?.subSection[1]?.description
  const [text, setText] = useState({
    headline,
    titleOne,
    descriptionOne,
    titleTwo,
    descriptionTwo,
  })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ headline, titleOne, descriptionOne, titleTwo, descriptionTwo })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([
          headline,
          titleOne,
          descriptionOne,
          titleTwo,
          descriptionTwo,
        ])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <div css={[tw`sm:pt-16 pt-6 w-full`]}>
      <SectionHeading textStyles={tw`sm:mb-16 mb-10 ml-0 mr-0 font-extrabold`}>
        {text?.headline}
      </SectionHeading>
      <div css={[tw`flex flex-col justify-center items-center`]}>
        <div css={tw`flex lg:flex-row flex-col-reverse mb-10`}>
          <div css={[tw`flex flex-col lg:w-1/2 w-full mb-0`]}>
            <h2 css={[tw`text-2xl font-extrabold lg:mt-0 mt-10 mb-4`]}>
              {text?.titleOne}
            </h2>
            <p
              dangerouslySetInnerHTML={{
                __html: text?.descriptionOne,
              }}
              css={[tw`text-lg mb-4 font-medium`]}
            />
          </div>
          <Image
            src={imageSrc1}
            publicURL={section?.subSection[0]?.image.localFile?.publicURL}
            alt="Heavy Template Section1 Img"
            css={[tw`lg:w-1/2 w-full lg:ml-10 object-contain`]}
          />
        </div>
        <div css={tw`flex lg:flex-row flex-col-reverse`}>
          <Image
            src={imageSrc2}
            publicURL={section?.subSection[1]?.image.localFile?.publicURL}
            alt="Heavy Template Section2 Img"
            css={[tw`lg:w-1/2 w-full lg:mr-10 object-contain`]}
          />
          <div css={[tw`flex flex-col lg:w-1/2 w-full lg:mb-0 mb-10 `]}>
            <h2 css={[tw`text-2xl font-extrabold mb-4`]}>{text?.titleTwo}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: text?.descriptionTwo,
              }}
              css={[tw`text-lg mb-4 font-medium`]}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AlternatingTextAndImage
