import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SectionHeading from "../../SectionHeading/SectionHeading"
import TranslateClient from "../../../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require("../../../helpers")
import { LanguageContext } from "../../../context/Language"
import { Image } from "../../Image"
interface ITopPrimaryImage {
  section: any
}

const TopPrimaryImage: React.FC<ITopPrimaryImage> = ({ section }) => {
  const imageSrc = getImage(section?.primaryImage?.localFile)

  const headline = section?.header
  const title = section?.subSection[0]?.title
  const description = section?.subSection[0]?.description
  const [text, setText] = useState({ headline, title, description })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ headline, title, description })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([
          headline,
          title,
          description,
        ])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <div css={[tw`sm:pt-20 pt-10 w-full`]}>
      <SectionHeading
        textStyles={[tw`sm:mb-16 mb-10 ml-0 mr-0 font-extrabold`]}
      >
        {text?.headline}
      </SectionHeading>
      <div css={[tw`flex flex-col justify-center items-center`]}>
        <Image
          src={imageSrc}
          publicURL={section?.primaryImage?.localFile?.publicURL}
          alt="Heavy Template Section1 Img"
          css={[tw`object-contain`]}
          style={{ maxHeight: "448px" }}
        />
        <div
          css={[
            tw`flex flex-col text-center space-y-4 sm:w-full max-w-screen-lg mb-6`,
          ]}
        >
          <h2 css={[tw`text-2xl font-extrabold sm:mt-16 mt-10`]}>
            {text?.title}
          </h2>
          <p
            dangerouslySetInnerHTML={{
              __html: text?.description,
            }}
            css={[tw`text-lg mb-4 font-medium`]}
          />
        </div>
      </div>
    </div>
  )
}

export default TopPrimaryImage
