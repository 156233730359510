import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import TranslateClient from "../../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../../helpers"
import { LanguageContext } from "../../../context/Language"

export type SubsectionProps = {
  title?: string
  description?: string
}

const Subsection: React.FC<SubsectionProps> = ({ title, description }) => {
  const [text, setText] = useState({ title, description })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ title, description })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([title, description])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <div css={tw`flex lg:flex-row flex-col-reverse mb-10`}>
      <div css={[tw`flex flex-col w-full mb-0`]}>
        <h2 css={[tw`text-2xl font-extrabold mb-4`]}>{text?.title}</h2>
        <p
          dangerouslySetInnerHTML={{ __html: text?.description }}
          css={[tw`text-lg mb-4 font-medium`]}
        />
      </div>
    </div>
  )
}

export default Subsection
