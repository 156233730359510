import React from "react"
import tw from "twin.macro"
import { Image } from "../Image"

import { getImage } from "gatsby-plugin-image"

interface IImageHero {
  styling?: any
  image: any
  overlay?: boolean
}

const ImageHero: React.FC<IImageHero> = ({
  styling,
  image,
  overlay,
  children,
}) => {
  const imageSrc = getImage(image?.node?.localFile)

  return (
    <section css={[tw`w-full h-80 relative`, styling]}>
      <Image
        src={imageSrc}
        publicURL={image?.node?.localFile?.publicURL}
        aria-hidden
        alt=""
        css={[tw`w-full h-80 object-cover`]}
      />
      {overlay && (
        <div css={[tw`bg-extraDarkGray opacity-60 absolute inset-0`]} />
      )}
      {children && (
        <div
          css={tw`absolute inset-0 flex flex-col justify-center content-center py-10`}
        >
          {children}
        </div>
      )}
    </section>
  )
}

export default ImageHero
